import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Footer.scss';
//import { FooterForm } from './FooterForm';

export const Footer = () => {
    return (
        <>
            <div className="abs-footer-background">
                <div>
                    <Container>
                        <Row style={{ margin: 0 }}>
                            <Col sm={12} md={{ offset: 2, span: 8 }} lg={{ offset: 3, span: 6 }} xl={{ offset: 4, span: 4 }}>
                                {/*
                                <FooterForm></FooterForm>
                                */}
                            </Col>
                        </Row>
                    </Container>
                    {/*
                    <div className="abs-footer-form-container">
                        <h2 className="abs-footer-title">Prueba Abstrakty  <br></br> GRATIS por 15 días</h2>
                    </div>
                    */}
                    <img src="/assets/icon_multicolor.png" alt="Abstrakty icon" className="abs-footer-icon"></img>
                    {/*
                    <div className="abs-footer-menu">
                        <p className="abs-footer-center">
                            <span className="abs-footer-menu-item">Terminos y condiciones</span>
                            <span className="abs-footer-menu-item">Aviso de privacidad</span>
                        </p>
                    </div>
                    <div className="abs-footer-menu">
                        <p className="abs-footer-center">
                            <span className="abs-footer-menu-item">🇲🇽 Español</span>
                            <span className="abs-footer-menu-item">🇺🇸 English</span>
                        </p>
                    </div>
                    */}
                    <p className="abs-footer-copyright">&copy; Copyright 2020 abstrakty.com</p>
                </div>
            </div>
        </>
    );
}