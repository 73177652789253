import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import getVideoURL from '../services/player';
import './Player.scss'

export const Player = () => {

    const history = useHistory() as any;
    console.log(history);
    const params = useParams() as any;
    var res = getVideoURL(params.id)
    if(!res) {
        history.replace('/not-found')
    }

    return(
        <>
            <div className="abs-player-container">
                <div className="abs-video-container">
                    <iframe title="Youtube video" width="100%" height="100%" src={`https://www.youtube.com/embed/${res}?controls=0&modestbranding=0&rel=0&showinfo=0&autoplay=1`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>
            <div className="abs-player-background"></div>
            <div className="abs-player-blur"></div>
            <div className="abs-icon-container">
                <p className="abs-play-text"
                    onClick={() => { history.goBack(); }}>
                    <FontAwesomeIcon icon={faTimesCircle} className="abs-section2-icon-right"></FontAwesomeIcon>
                </p>
            </div>
        </>
    )
}